<template>
  <div
    class="goods-item"
    :style="$route.path == '/management' || $route.path == '/artistDetails' ? 'height: 56vw;' : ''"
  >
    <div
      class="item-li itme-li-img"
      :style="$route.path == '/management' || $route.path == '/artistDetails' ? 'height: 56vw;' : ''"
      style="overflow:hidden"
      v-if="goodsType == 'img'"
    >
      <!-- :style="aspectRatio ? 'width: 100%;height: auto;' : 'width: auto;height: 100%;'" -->
      <div class="img-main">
        <img :src="goodsSource || goodsSrc" />
      </div>

      <div
        v-if="$route.path !== '/management' && $route.path !== '/artistDetails' &&  $route.path !== '/seriesCollectionDetails'"
        class="img-scale"
        @click="imagePreview(goodsSource || goodsSrc)"
      >
        <img
          src="../assets/icon/media/scale.png"
          alt=""
        >
      </div>
      <van-image-preview
        v-model="showImg"
        :images="imgList"
        :show-index="false"
        :max-zoom="3"
        :min-zoom="'1/3'"
        @scale="imageScale"
      >
        <!-- <template v-slot:cover>
          <img
            class="img-close"
            src="../assets/icon/media/close.png"
            alt=""
            @click="() => showImg = false"
          />
          <img
            @click="rotataImg"
            class="img-rotate"
            src="../assets/icon/media/rotate.png"
            alt=""
          />
        </template> -->
      </van-image-preview>
      <img
        v-if="showImg"
        class="img-close"
        src="../assets/icon/media/close.png"
        alt=""
        @click="() => showImg = false"
      />
      <img
        v-if="showImg"
        @click="rotataImg"
        class="img-rotate"
        src="../assets/icon/media/rotate.png"
        alt=""
      />
    </div>
    <div
      class="item-li vido"
      style="overflow:hidden"
      :class="$route.path == '/management' || $route.path == '/artistDetails' ? 'management-style' : 'nomal-style'"
      v-else-if="goodsType == 'video'"
      @click="videoClick"
    >
      <video-player
        v-if="goodsSource"
        x5-video-player-type='h5-page'
        id="video"
        ref="video-player"
        :style="$route.path == '/management' || $route.path == '/artistDetails' ? 'height: 56vw;' : ''"
        class="video-player-box"
        :options="playerOptions"
        @pause="onPlayerPause($event)"
        @play="onPlayerPlay($event)"
        @canplay="onPlayerCanplay($event)"
        :playsinline="true"
      />
      <img
        v-if="!goodsSource"
        class="poster"
        :src="goodsPoster"
        alt=""
        :style="aspectRatio ? 'width: 100%;height: auto;' : 'width: auto;height: 100%;'"
      >
      <img
        v-if="!isPlay"
        class="player"
        src="../assets/icon/play.png"
        alt=""
      >
      <div class="tilt"></div>
    </div>
    <div
      class="item-li model-container"
      style="overflow:hidden"
      v-else-if="goodsType == 'audio'"
    >
      <div
        class="audio"
        @click="swichAudio"
      >
        <!-- <audio
          src="../assets/audio/Visions.mp3"
          controls
          autoplay
          loop
          preload="auto"
        ></audio> -->
        <audio-player
          ref="audioPlayer"
          :audio-list="audioList.map(elm => elm.url)"
          :before-play="handleBeforePlay"
          theme-color="#FFFFFF"
          v-bind="audioObj"
        />
        <div class="process-bg"></div>
        <div class="cover">
          <img
            :class="`${audioIsPlay ? 'audio-rotate' : 'audio-paused'}`"
            :src="goodsPoster"
            alt=""
          >
        </div>
        <img
          v-if="!audioIsPlay"
          class="player"
          src="../assets/img/media/audio_play.png"
          alt=""
        >
      </div>
    </div>
    <div
      class="item-li model-container"
      style="overflow:hidden"
      v-else-if="goodsType == '3d'"
    >
      <!-- 正常3d -->
      <div
        class="model"
        :style="goodsBackground ? 'background: url(' + goodsBackground + ') 50% 50% no-repeat;background-size: 100% auto;' : 'background: url('+require(`../assets/img/media/model_bg_1.png`) +') 50% 50% no-repeat;background-size: 100% auto;'"
      >
        <div
          id="model"
          :class="{'model-is-loaded': modelIsLoaded,'model-not-loaded': !modelIsLoaded, }"
        ></div>
        <div
          class="row-center"
          style="position: absolute;bottom: 0.625rem;width: 100vw;"
        >
          <div
            class="scale"
            @click="initScale"
          >
            <img
              src="../assets/icon/media/scale.png"
              alt=""
            >
          </div>
          <div
            class="refresh"
            @click="initRotate"
          >
            <img
              src="../assets/icon/media/refresh.png"
              alt=""
            >
          </div>
        </div>

        <div
          v-show="!modelIsLoaded"
          class="loading"
        >
          <div class="loading-top">
            <img
              src="../assets/icon/media/model_loading.png"
              alt=""
            >
            <p>{{loadingTip}}</p>
          </div>
          <div>
            <van-progress
              :percentage="progress"
              stroke-width="4"
              color="#FFFFFF"
              track-color="#A2A2A2"
              :show-pivot="false"
            />
            <!-- <van-progress
              :percentage="50"
              stroke-width="2"
              color="#A2A2A2"
            /> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import videoJs from 'video.js'
import AudioPlayer from '@liripeng/vue-audio-player'
import 'videojs-flash'
// import 'videojs-hotkeys'
import Vue from 'vue';
import { Progress,ImagePreview } from 'vant';

Vue.use(Progress);


export default {
  name: 'GoodsItem',
  components: {
    videoPlayer,
    AudioPlayer,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  props: {
    videoIsPlay: {
      type: Boolean,
      default: () => false
    },
    goodsType: {
      type: String,
      default: () => ''
    },
    goodsSrc: {
      type: String,
      default: () => ''
    },
    goodsPoster: {
      type: String,
      default: () => ''
    },
    goodsSource: {
      type: String,
      default: () => ''
    },
    goodsBackground: {
      type: String,
      default: () => ''
    },
  },
  data () {
    return {
      aspectRatio: false,
      isEqual: false, // 是否等宽高
      playerOptions: {
        // videojs options
        muted: false,
        autoplay: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        fluid: true,
        // aspectRatio: "16:9",
        // playbackRates: [0.7,1.0,1.5,2.0],
        // sources:
        //   "",
        sources: [{
          type: "video/mp4",
          src: ''
        }],

        poster:
          "",
        // techOrder: ['flash'],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        // controlBar: {
        //   timeDivider: false, //当前时间和持续时间的分隔符
        //   durationDisplay: false, //显示持续时间
        //   remainingTimeDisplay: false,
        //   volumeControl: false,
        //   fullscreenToggle: false, // 全屏按钮
        // },
        controlBar: false
      },
      audioList: [
        // {
        //   name: 'audio1',
        //   // url: 'https://www.0dutv.com/upload/dance/F25F74A0B8FF82503241801D0E2CA5CD.mp3',
        //   url: require('../assets/audio/Visions.mp3'),
        // },
        // {
        //   name: 'audio2',
        //   url: 'https://www.0dutv.com/upload/dance/20200316/C719452E3C7834080007662021EA968E.mp3'
        // }
      ],
      audioObj: {
        showPlayButton: false,
        showPrevButton: false,
        showNextButton: false,
        showVolumeButton: false,
        showPlaybackRate: false,
        disabledProgressDrag: true,
        disabledProgressClick: true,
        // showPrevButton: false,
      },
      isPlay: false,
      audioIsPlay: false,
      viewer: {},
      model: {},
      modelIsLoaded: false,
      modelView: null,
      progress: 0,
      tipIndex: 0,
      loadingTip: '加载中',
      imgList: [],
      showImg: false,
      rotateDeg: 0
    }
  },
  watch: {
    goodsType (val) {
      this.init()
    },
    videoIsPlay () {
      if (this.$refs['video-player']) {
        this.$refs['video-player'].player.pause()
      }

    }
    // goodsSrc (val) {
    //   this.init()
    // }
  },
  mounted () {
    this.init()

  },
  beforeDestroy () {
    if (this.goodsType == '3d') {
      this.viewer.modelManager && this.viewer.modelManager.remove(this.model)
      if (this.model && this.model.dispose) {
        this.model.dispose()

      }
    }

  },
  methods: {
    init () {
      if (this.goodsType == 'img') {
        let img = new Image()
        let url = this.goodsSource || this.goodsSrc
        img.src = url
        // this.$store.commit('SHOW_APPLOADING')
        setTimeout(() => {
          if (img.width == img.height) {
            this.isEqual = true
          } else {
            this.isEqual = false
            this.aspectRatio = (img.width / img.height) < 1 ? false : true
          }

          // this.$store.commit('HIDE_APPLOADING')
        },1500)
      } else if (this.goodsType == 'video') {
        this.playerOptions.sources[0].src = this.goodsSource
        this.playerOptions.poster = this.goodsPoster
        let img = new Image()
        let url = this.goodsPoster
        img.src = url
        this.aspectRatio = (img.width / img.height) < 1 ? false : true


      } else if (this.goodsType == '3d') {
        setTimeout(() => {
          this.viewer = new AMRT.Viewer('model', /*容器dom或者dom对应的id*/ { offline: true })
          this.initProgress()
          this.viewer.loadModel(this.goodsSource.replace('.zip','/'),{
            offline: true,
            focus: true,
            background: false,
            onLoad: (model) => {
              this.model = model
              this.progress = 100


              setTimeout(() => {
                this.modelIsLoaded = true
              },1000)
              this.modelView = this.viewer.controls.getView()
              // this.viewer.controls.fit(this.model)
              this.viewer.fitCamera(this.model)
              this.viewer.sceneManager.scene.background = null
              this.viewer.domElement.style.backgroundColor = ''

              this.viewer.sceneManager.setDirectLightLightness(0.01)
              this.viewer.controls.startAutoRotate(0.8)
              this.viewer.controls.enableZoom = false
              // 以下页面需要判断在模型加载前是否后退
              let routeWhiteList = ['/modelDetails','/compositeRecordDetail','/compositeDetail','/blindDetails','/goodDetails','/management','/seriesCollectionDetails','/artistDetails','/collection']
              // 挂在异常手动清除dom
              setTimeout(() => {
                if (!routeWhiteList.includes(this.$route.path)) {
                  this.viewer.modelManager.remove(this.model)
                  this.model.dispose()
                  var bodyDom = document.getElementsByTagName("body")[0]
                  if (bodyDom) {
                    let arr = bodyDom.getElementsByTagName("canvas")
                    for (var i = 0; i < arr.length; i++) {
                      bodyDom.removeChild(arr[i])
                    }
                  }
                }
              },100)
            },
            onError: (err) => {
              this.$toast({
                message: '模型初始化失败',
                icon: require('../assets/icon/toast-error.png'),
              });
            }
          })
        },1000)
      } else {
        setTimeout(() => {
          this.initAudio()
        },1000)

      }
    },
    initVideo () {
      // alert(2)
      return new Promise((resolve,reject) => {
        let wid = this.$refs['video-player'].player.videoWidth(),
          hei = this.$refs['video-player'].player.videoHeight()
        if (wid && wid) {
          if (wid < hei) {
            var dom = document.getElementById('video')
            dom.style.height = "100%"
            dom.style.width = `${(wid / hei) * 100}%`
          } else {
            var dom = document.getElementById('video')
            dom.style.width = "100%"
            dom.style.height = `${(hei / wid) * 100}%`
          }
          resolve()
        } else {
          reject()
        }
      })

    },
    onPlayerPause (e) {
      this.isPlay = false
    },
    onPlayerPlay (e) {
      this.isPlay = true
    },
    onPlayerCanplay (e) {
      this.initVideo()
    },
    videoClick () {
      if (this.goodsSource) {
        this.playerOptions.muted = false
        // alert(1)
        this.initVideo()
          .then(() => {
            if (!this.isPlay) {
              this.$refs['video-player'].player.play()
            } else {
              this.$refs['video-player'].player.pause()
            }
          })
      }

    },
    handleBeforePlay (next) {
      // There are a few things you can do here...
      // this.currentAudioName = this.audioList[this.$refs.audioPlayer.currentPlayIndex].name

      next() // Start playing
    },
    initAudio () {
      this.audioList.push({
        name: 'audio2',
        url: this.goodsSource
      })
    },
    swichAudio () {
      if (this.audioIsPlay) {
        this.$refs.audioPlayer.pause()
      } else {
        this.$refs.audioPlayer.play()
      }

      this.audioIsPlay = !this.audioIsPlay

    },
    initProgress () {
      this.progress = 1

      const intervalId = setInterval(() => {
        // debugger
        let speed = Math.floor(100 / (1000000 / 65000))
        if (this.tipIndex == 3) {
          this.tipIndex = 0
        }
        this.loadingTip = `加载中${this.tipIndex == 1 ? '.' : this.tipIndex == 2 ? '..' : '...'}`

        this.tipIndex += 1
        // 控制进度条防止在未上传成功时进度条达到100
        if (this.progress < 99 && this.progress + speed < 100) {
          this.progress += speed//控制进度条速度
          // data.onProgress(progress)//onProgress接收一个对象{ percent: 进度 }在进度条上显示
        } else if ((this.progress === 99)) {
          this.progress++
        } else if (this.progress === 100) {
          clearInterval(intervalId)
        }
      },300)
    },
    setImgSize () {
      // 如果等宽高则不用调整图片宽高比
      if (!this.isEqual) {
        if (this.aspectRatio) {
          var dom = document.getElementsByClassName('van-image__img')
          if (dom[0]) {
            dom[0].style.width = `100vw`
            dom[0].style.height = `100vw`
            dom[0].style['object-fit'] = 'auto'
          }
        } else {

          var dom = document.getElementsByClassName('van-image__img')
          if (dom[0]) {
            dom[0].style['object-fit'] = 'auto'
            dom[0].style.width = `100vh`
            dom[0].style.height = `100vh`
          }
        }
      }

    },
    imagePreview (url) {
      // ImagePreview({
      //   images: [
      //     url
      //   ],
      //   closeable: true,
      // });
      this.showImg = true
      this.imgList = [url]
      setTimeout(() => {
        this.setImgSize()
      },1000)
    },
    rotataImg () {
      var dom = document.getElementsByClassName('van-image__img')
      if (dom[0]) {
        this.rotateDeg += 90

        dom[0].style.transform = `rotate(${this.rotateDeg}deg)`
        // 如果等宽高则不用调整图片宽高比
        if (!this.isEqual) {
          this.aspectRatio = !this.aspectRatio
          this.setImgSize()
        }


      }
    },
    initRotate () {

      this.viewer.controls.flyTo(this.modelView)
      this.viewer.controls.stopAutoRotate(0)

      setTimeout(() => {
        this.viewer.fitCamera(this.model)
        this.viewer.controls.startAutoRotate(0.8)
      },1000)
    },
    initScale () {
      this.$store.commit('SHOW_APPLOADING')
      localStorage.setItem('3DGoodsSrc',this.goodsSrc)
      localStorage.setItem('3DGoodsPoster',this.goodsPoster)
      localStorage.setItem('3DGoodsSource',this.goodsSource)
      if (this.goodsBackground) {
        localStorage.setItem('goodsBackground',this.goodsBackground)
      } else {
        localStorage.removeItem('goodsBackground')
      }
      this.$router.push('/modelDetails')
    },
    imageScale (index,scale) {
    }


  }
}
</script>

<style lang="less" scoped>
.goods-item {
  width: 100%;
  font-family: lantingheiweight;
  // height: 100%;
  // .img-pre {
  //   position: fixed;
  // }
  .img-close {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    position: fixed;
    top: 1rem;
    left: 1rem;
    right: auto;
    bottom: auto;
    z-index: 9999;
  }
  .img-rotate {
    width: 1.875rem;
    height: 1.6875rem;
    display: block;
    position: fixed;
    z-index: 9999;
    top: auto;
    left: 85vw;
    right: auto;
    bottom: 1.5rem;
  }
  .item-li {
    width: 100%;
    height: 96vw;
    position: relative;
    // position: absolute;
    // right: 17px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/.vjs-big-play-button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      // left: 150px;
      // top: 76px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    // /deep/.video-player-box {
    //   touch-action: none;
    //   .video-js .vjs-time-control {
    //     min-width: 0px;
    //     padding-left: 1px;
    //     padding-right: 1px;
    //     display: block;
    //   }
    //   .video-js .vjs-remaining-time {
    //     display: none;
    //   }
    // }
  }
  .itme-li-img {
    position: relative;
    display: flex;
    // position: relative;
    .img-scale {
      width: 2.6875rem;
      height: 2.6875rem;
      position: absolute;
      bottom: 0.625rem;
      right: 0.625rem;
    }
    .img-main {
      // display: inline-block;
      // text-align: center;
      // width: 100%;
      // height: 100%;
      // max-width: 100%;
      // max-height: 100%;
      // width: auto;
      // height: auto;
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // transform: translate3d(-50%, -50%, 0);
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
    /deep/ .van-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100% !important;
      width: 100% !important;
    }
    .van-image-preview {
      width: 100vw;
      height: 100vh;
      z-index: 3000 !important;
    }
    /deep/ .van-image-preview__overlay {
      background: #1c1c1c;
      z-index: 3000 !important;
    }
  }
  .model-container {
    width: 100%;
    height: 96vw;
    position: relative;
    .model {
      width: 100%;
      height: 100%;
      position: relative;
      .refresh {
        width: 1.9375rem;
        height: 1.9375rem;
      }
      .scale {
        width: 2rem;
        height: 2rem;
        margin-bottom: 10px;
        margin-right: 15px;
      }
    }
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15rem;
      &-top {
        width: 15rem;
        display: flex;
        align-items: center;
        margin-bottom: 1.3125rem;
        justify-content: center;
        img {
          width: 1.875rem;
          height: 1.875rem;
          display: block;
        }
        p {
          margin-left: 0.625rem;
          font-size: 0.875rem;
          color: #ffffff;
        }
      }
      .vant-progress {
        width: 15rem;
      }
    }
    #model {
      width: 100%;
      height: 100%;
    }
    .model-is-loaded {
      visibility: visible;
    }
    .model-not-loaded {
      visibility: hidden;
    }
  }
  ::v-deep .video-js .vjs-tech {
    // object-fit: fill;
  }
  ::v-deep .vjs-poster {
    background-size: cover;
  }
  /deep/ .vjs-progress-control {
    visibility: hidden;
  }
  /deep/ .vjs-volume-panel-horizontal {
    visibility: hidden;
  }
  .vido {
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // z-index: 0;
    #video {
      // border-radius: 8px;
      // overflow: hidden;
      width: 100%;
      height: 100%;
    }
    .tilt {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0; /*底部控件是3.0em*/
      z-index: 1;
    }
  }
  /deep/.video-js.vjs-fluid:not(.vjs-audio-only-mode) {
    height: 100% !important;
  }
  .audio {
    width: 100%;
    height: 100%;
    background: url('../assets/img/media/audio_bg_1.png') 50% 50% no-repeat;
    background-size: 100% auto;
    position: relative;
    .process-bg {
      width: 100%;
      height: 2.875rem;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .player {
      width: 4.25rem;
      height: 4.25rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .cover {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      img {
        display: block;
        width: 6rem;
        height: 6rem;
        border-radius: 5rem;
        overflow: hidden;
        -webkit-transition-property: -webkit-transform;
        -webkit-transition-duration: 1s;
        -moz-transition-property: -moz-transform;
        -moz-transition-duration: 1s;
        -webkit-animation: rotate 10s linear infinite;
        -moz-animation: rotate 10s linear infinite;
        -o-animation: rotate 10s linear infinite;
        animation: rotate 10s linear infinite;
      }
      .audio-rotate {
        animation-play-state: running;
      }
      .audio-paused {
        animation-play-state: paused;
      }
    }
    // div {

    // }
    @-webkit-keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-moz-keyframes rotate {
      from {
        -moz-transform: rotate(0deg);
      }
      to {
        -moz-transform: rotate(360deg);
      }
    }
    @-o-keyframes rotate {
      from {
        -o-transform: rotate(0deg);
      }
      to {
        -o-transform: rotate(360deg);
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .audio-player {
      position: absolute;
      bottom: 2rem;
      width: 90%;
      bottom: 1rem;
      transform: translateX(-50%);
      left: 50%;
      z-index: 100;
    }
    /deep/ .audio__time-wrap {
      display: none;
    }
  }
  .poster {
    width: 100%;
    height: 100%;
  }
  .player {
    width: 4.25rem;
    height: 4.25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  /deep/ .vjs-big-play-button {
    display: none;
  }
}
.management-style {
  height: 56vw !important;
  .video-player {
    height: 56vw !important;
    .vjs-fluid,
    .vjs-16-9,
    .vjs-4-3,
    .vjs-9-16,
    .vjs-1-1 {
      height: 56vw !important;
    }
    /deep/.vjs-tech {
      height: 56vw !important;
    }
  }
}
.nomal-style {
  .video-player {
    height: 100%;
  }
}
</style>